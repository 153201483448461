import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Mobile Dev HQ`}</p>
    <p>{`Working as a freelance product designer with mobile dev HQ. Their technology helps user to get higher rank in app store top charts. For their home page, I designed a page that you need to scroll up to represent their technology.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2013/08/graphs.jpg",
        "alt": "Visulization_guide"
      }}></img></p>
    <p>{`Top Charts Dashboard
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2012/08/top-charts.png",
        "alt": "Top_charts"
      }}></img></p>
    <p>{`App Portfolio Page
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2012/08/details.png",
        "alt": "App_portfolio_page"
      }}></img></p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      